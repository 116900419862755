import React from "react";
import "./App.css"
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";

//Importing Views
import PaymentComponent from "./PaymentWidget/PaymentComponent";


export default function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                        <PaymentComponent
                            keys={{
                                stripe: "pk_live_51JS7PHBvlilhJh9PEOs0TGRccUOCS6ZMuvKdszIRlVvlcUKQBGfAPzfkQ6wfnnq4PEMvOpvMjGMCAGT8qeW2th1D00rrHAb8tu",
                            }}
                        />
                </Route>
                <Route >
                       <PaymentComponent
                            keys={{
                                stripe: "pk_live_51JS7PHBvlilhJh9PEOs0TGRccUOCS6ZMuvKdszIRlVvlcUKQBGfAPzfkQ6wfnnq4PEMvOpvMjGMCAGT8qeW2th1D00rrHAb8tu",
                            }}
                        />
                </Route>
            </Switch>
        </Router>
    );
}
