import React, { useState } from "react";
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
//css provided by stripe to format elements
const axios = require("axios");

//credit card element specific styling
const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            fontWeight: 500,
            fontFamily: "Poppins, Open Sans, Segoe UI, sans-serif",
			fontSize: "18px",
			color: "#111",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
                   color: '#cccccc',
            },
            "::placeholder": {
                   color: '#888',
            },
        },
        invalid: {
            iconColor: "red",
            color: "red",
        },
    },
};

//scredit card button sub component
const CardField = ({onChange}) => (
    <div>
    <label className="formlabel">
     Card number
    </label>
    <CardNumberElement options={CARD_OPTIONS} onChange={onChange} />
     
    <div className="flex items-center">
    <div className="w-1/2 p-4">
    <label className="formlabel">
     Expiry date
    </label>
    <CardExpiryElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
    <div className="w-1/2 p-4">
    <label className="formlabel">
     CVC
    </label>
    <CardCvcElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
    </div>
    </div>
  );

//submit button sub component
const SubmitButton = ({ processing, error, children, disabled }) => (
    <button
        className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
        type="submit"
        disabled={processing || disabled}
    >
        {processing ? "Processing..." : children}
    </button>
);

//component declaration
export default function CreditCardForm(props) {

    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [paymentSuccessful, setPaymentSuccessful] = useState(false);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [price, setPrice] = useState("");
    const [billingDetails, setBillingDetails] = useState({
        email: ''
    });

    //resets state on completion
    const reset = () => {
        setError(null);
        setProcessing(false);
        setPrice("");
        setCardComplete(false);
        setBillingDetails({
        email: ''
        });
    };

    /*
	This code runs when a card transaction is submitted
	There are three main components to this function:
		1. create a new stripe payment method using the form data
		2. get a payment intent from the server using the speficied price
		3. confirm the payment intent using the new payment method
		4. send a confiemation to the server if the payment succeeded
	*/
    const handleSubmit = async (event) => {
        //prevent default form values
        event.preventDefault();

        ///if stripe api is loaded
        if (!stripe || !elements) {
            return;
        }

        //handle errors
        if (error) {
            console.log(error);
            elements.getElement("card").focus();
            return;
        }

        if(price === 0) {
            return;
        }

        //start processing animation on submit button
        if (cardComplete) {
            setProcessing(true);
        } else {
            return;
        }

		//STEP 1:
        //create new payment method based on card and form information
        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement),
            billing_details: billingDetails
        });

        //handle errors, otherwise set the new payment method in state
        if (payload.error) {
            setError(payload.error.message);
            return setProcessing(false)
        } 
		
		//STEP 2:
        //create a new payment request and get irs client secret + id from the server
        const intentData = await axios
            .post("/stripe", {
                //include the bet amount
                price: price,
                email: billingDetails.email
            })
            .then(
                (response) => {
                    //SUCCESS: put client secret and id into an object and return it
                    return {
                        secret: response.data.client_secret,
                        id: response.data.intent_id,
                    };
                },
                (error) => {
                    //ERROR: log the error and return
                    setProcessing(false)
                    return setError(error.message)
                }
            );
		
		//STEP 3:
        //confirm the payment and use the new payment method
        const result = await stripe.confirmCardPayment(intentData.secret, {
            payment_method: payload.paymentMethod.id,
        });

        //handle errors again
        if (result.error) {
            setProcessing(false)
           return setError(result.error.message);
        }
		
		//STEP 4:
        // The payment has been processed! send a confirmation to the server
        if (result.paymentIntent.status === "succeeded") {
            const confirmedPayment = await axios
                .post("/confirm-payment", {
                    //include id of payment
                    payment_id: intentData.id,
                    payment_type: "stripe",
                    //send any other data here
                })
                .then(
                    (response) => {
                        //SUCCESS: return the response message
                        return response.data.success;
                    },
                    (error) => {
                        //ERROR:
                        setProcessing(false)
                        return setError(error.message)
                    }
                );

            //reset the state and show the success message
            if (confirmedPayment) {
                setPaymentSuccessful(true)
                //reset the form
                reset();
                /*
                 YOUR APPLICATION SPECIFIC CODE HERE:
                 for this example all we do is render a modal
                */
            }
        }
    }

    

    //render
    return (
        // the credit card form
        <form style={{zIndex:"2"}} className="shadow-lg p-10 md:px-16 lg:px-32 mb-16 pb-10 bg-white" onSubmit={handleSubmit}>
         {
             !paymentSuccessful ?
             (
                <>
                <p style={{fontSize:"1.2rem",letterSpacing:"0.5px",width: "100%",maxWidth: "500px"}} className="text-gray-800 text-center font-medium uppercase mb-10">Please input the amount agreed to be paid and make payment.</p>

                {/* Amount field */}
                <div className="mb-10 relative">
                <label className="formlabel" htmlFor="amount">
                Amount in USD
                </label>
                <div className="inline-block relative w-full">
                <input style={{width: "100%", maxWidth: "500px"}}
                className="block appearance-none shadow rounded w-full py-4 px-4 leading-tight focus:outline-none focus:shadow-outline " 
                id="amount" type="number"
                required
                name="amount"
                value={price}
                onChange={(event) => {
                if (event.target.value >= 0){
                    setPrice(event.target.value);
                }
                }}
                />
                </div>
                </div>

                {/* Email field */}
                <div className="mb-10">
                <label className="formlabel" htmlFor="email">
                Email
                </label>
                <div className="inline-block relative w-full">
                <input style={{width: "100%", maxWidth: "500px"}}
                className="block appearance-none shadow rounded w-full py-4 px-4 leading-tight focus:outline-none focus:shadow-outline " 
                id="email" type="email"
                required
                name="email"
                value={billingDetails.email}
                onChange={(event) => {
                setBillingDetails({...billingDetails, email: event.target.value});
                }}
                />
                </div>
                </div>


                {/* credit card field and submit button */}
                {error &&
                (<p style={{fontSize:"1.4rem"}} className="font-semibold text-red-500 text-center my-2 border-t border-b border-red-500 py-4">ERROR: {error}</p>)
                }
               
                    {/* card */}
                    <CardField
                        onChange={(event) => {
                            if(event.error){
                                setError(event.error.message);
                            }else{
                                setError(null)
                            }
                            setCardComplete(event.complete);
                        }}
                    />

                {/* submit */}
                <SubmitButton
                        processing={processing}
                        error={error}
                        disabled={!stripe}
                    >
                        Make Secure Payment
                </SubmitButton>
               
                </>
             )

             :

             (
                <div className="w-full flex flex-col items-center justify-center">
                <img src={`${window.location.origin}/images/checked.png`} alt="" className="mb-2" style={{height:"100px"}}/>
                <p className="text-blue-600 font-medium text-3xl text-center mt-6">PAYMENT PROCESSED SUCCESSFULLY</p>
                <p className="font-semibold text-6xl mt-16">
                  Thank you!
                </p>
          
                <a href="https://21sagestudios.com/" style={{borderRadius:"4px"}} className="hover:pointer bg-blue-600 hover:bg-blue-800 px-6 py-4 text-white text-3xl font-medium my-10">
                  Visit our website
                </a>
                </div>
             )
         }
        </form>
    );
    
}